import {
  IAlarm,
  IAssetIcon,
  IBoxDevice,
  IBoxDeviceWithInstance,
  IBoxInstance,
  IBuildingGroup,
  IBuildingObject,
  IClient,
  IClientRole,
  IDeviceClass,
  IDeviceClassLoookupField,
  IDeviceFieldValue,
  IMultiCallItem,
  IUsageBuilding,
} from "@/lib/interfaces";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";

import { omniApi, passParams } from "../../omniApi";
import { queryParams } from "./riskRadar";

export interface IContract {
  id: string;
  clientId: string;
  name: string;
}

export interface IBuilding {
  id: string;
  clientId: string;
  contractId: string;
  name: string;
}

export const customQueriesAPI = omniApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAlarmsWithHistory: builder.query<
      { alarms: IAlarm[] },
      {
        client: string;
        building: string;
      }
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        // prep variables
        const { client, building } = _arg;
        const results: IAlarm[] = [];
        const alarms: IAlarm[] = [];
        const alarmsRequest = await fetchWithBQ(
          `/client/${client}/alarms?data=${passParams({ params: { complete: 1 } })}`
        );
        const alarmHistoryRequests: Array<
          MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError>>
        > = [];

        try {
          alarmsRequest.data?.data?.map((alarm: IAlarm) => {
            if (alarm.building === building) {
              alarms.push(alarm);
            }
          });
        } catch (e) {}

        alarms.map(({ id }) => {
          alarmHistoryRequests.push(
            fetchWithBQ(
              `/client/${client}/module/alarm/getAlarmHistory?data=${passParams(
                {
                  params: { complete: 1, alarmId: id, deviceId: 0 },
                }
              )}`
            )
          );
        });

        const responses = await Promise.all(alarmHistoryRequests);
        responses.map(({ data, error, meta }) => {
          try {
            const url = meta.request.url.split("/");
            const queryString = url[9].split("=");
            const obj = JSON.parse(decodeURIComponent(queryString[1]));

            const findAlarm = alarms.find((alarm) => alarm.id === obj.alarmId);

            if (findAlarm) {
              results.push({ ...findAlarm, history: data.alarmStatusChanges });
            }
          } catch (e) {}
        });

        return {
          data: {
            alarms: results,
          },
        };
      },
    }),

    getDevicesAnnotations: builder.query<
      { devices: IBoxDeviceWithInstance[] },
      {
        client: string;
        devices: IBoxDeviceWithInstance[] | undefined;
      }
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        //prep variables
        const response: IBoxDeviceWithInstance[] = [];
        const deviceFieldValues: IDeviceFieldValue[] = [];
        const deviceClassLoookupFields: IDeviceClassLoookupField[] = [];
        const { devices } = _arg;
        const boxInstanceIds: string[] = [];
        const fieldValuesRequests: Array<
          MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError>>
        > = [];
        //get deviceFieldValues
        devices?.map((d) => {
          if (!boxInstanceIds.includes(d.instance?.id!)) {
            boxInstanceIds.push(d.instance?.id!);
          }
        });

        devices?.map((device) => {
          fieldValuesRequests.push(
            fetchWithBQ(
              `/boxInstance/${device.instance?.id}/deviceFieldValues?data=${passParams(
                {
                  params: {
                    complete: 1,
                    // filters: [{ "field": "device", "value": device.id }]
                  },
                }
              )}`
            )
          );
        });

        const responses = await Promise.all(fieldValuesRequests);
        responses.map(({ data, error, meta }) => {
          try {
            deviceFieldValues.push(...data?.data);
          } catch (e) {
            //error when API down?
          }
        });

        const deviceClassFieldsRequest = await fetchWithBQ(
          `/deviceClassFields?data=${passParams({ params: { complete: 1 } })}`
        );
        try {
          if (deviceClassFieldsRequest.data.status === "success") {
            deviceClassLoookupFields.push(
              ...deviceClassFieldsRequest.data.data
            );
          }
        } catch (e) {}

        devices?.map((d) => {
          const tempDevice = { ...d };
          const annotations: IDeviceClassLoookupField[] = [];
          let annotatedAs: IDeviceClassLoookupField | undefined = undefined;

          if (d.deviceClass) {
            //device -> has sensors annotated -> MULTIPLE
            deviceClassLoookupFields.map((field) => {
              if (field.deviceClass === d.deviceClass) {
                const deviceFieldValue = deviceFieldValues.find(
                  (item) =>
                    item.device === d.id && item.deviceField === field.id
                );
                annotations.push({
                  ...field,
                  deviceValue: deviceFieldValue?.deviceValue,
                });
              }
            });
          } else {
            //sensor -> can be annotated at -> SINGLE
            const deviceFieldValue = deviceFieldValues.find(
              (item) => item.deviceValue === d.id
            );
            if (deviceFieldValue) {
              annotatedAs = deviceClassLoookupFields.find(
                (item) => item.id === deviceFieldValue.deviceField
              );
            }
          }
          response.push({ ...tempDevice, annotations, annotatedAs });
        });

        return {
          data: {
            devices: response,
          },
        };
      },
    }),

    getDevicesBy: builder.query<
      { devices: IBoxDeviceWithInstance[] },
      {
        client: string;
        field: string;
        byCollectionOf: string[];
        incParent?: boolean;
        instances?: string[];
      }
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const { field, byCollectionOf, incParent, instances } = _arg;
        if (byCollectionOf === undefined) return { data: { devices: [] } };
        const devices: IBoxDeviceWithInstance[] = [];
        let boxInstancesList: IBoxInstance[] = [];
        const requests: Array<
          MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError>>
        > = [];
        const multicallRequests: Array<{ path: string; biid: string }> = [];
        const parentRequests: Array<
          MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError>>
        > = [];

        if (instances) {
          boxInstancesList = instances;
        } else {
          const boxInstances = await fetchWithBQ(
            `/boxInstances?data=${passParams({ params: { complete: 1, filters: [{ field: "client", value: _arg.client }] } })}`
          );
          boxInstances.data?.data?.map((boxInstance: IBoxInstance) =>
            boxInstancesList.push(boxInstance)
          );
        }

        boxInstancesList.map(({ id: biid }) => {
          multicallRequests.push({
            path: `/rest/dbo/boxInstance/${biid}/boxDevices`,
            biid,
          });
        });

        const devicesOR = byCollectionOf.map((fieldValue) => {
          return {
            field,
            value: fieldValue,
          };
        });
        if (devicesOR.length === 0) return { data: { devices: [] } };

        const multicallResponse = await fetchWithBQ({
          url: `${process.env.NEXT_PUBLIC_SERVER_URL}/rest/multicall`,
          method: "POST",
          body: {
            calls: multicallRequests.map(({ path, biid }) => {
              return {
                biid,
                path,
                method: "GET",
                data: {
                  complete: 1,
                  filters: { OR: devicesOR },
                },
              };
            }),
          },
        });

        multicallResponse.data?.responses?.map(
          (response: any, index: number) => {
            if (response.status === "success") {
              let boxId = 0;

              Object.keys(response.timings).map((key) => {
                if (key.includes("boxInstance")) {
                  boxId = +key.split("/")[1];
                }
              });

              const instance = boxInstancesList.find(
                (item) => item.id == boxId
              );
              response?.data?.map((d) => {
                devices.push({ ...d, instance });
              });
            }
          }
        );

        if (incParent) {
          //reqest parents and push on the same object!
          // parentItem
          const parentCalls: IMultiCallItem[] = [];

          devices.map(async (d) => {
            const parentId =
              +d?.virtualParentDevice > 0
                ? d.virtualParentDevice
                : d.parentItem;

            const checkParentId = parentCalls.find(
              (p) => p.data.filters[0].value == parentId
            );
            if (checkParentId) return;

            parentCalls.push({
              biid: d.instance?.id,
              method: "GET",
              path: `/rest/dbo/boxInstance/${d.instance?.id}/boxDevices`,
              data: {
                complete: 1,
                filters: [{ field: "id", value: parentId }],
              },
            });
          });

          const parentMulticallResponse = await fetchWithBQ({
            url: `${process.env.NEXT_PUBLIC_SERVER_URL}/rest/multicall`,
            method: "POST",
            body: {
              calls: parentCalls,
            },
          });

          const parentDevices = [];
          parentMulticallResponse?.data?.responses?.map((t: any) => {
            t?.data?.map((d: any) => {
              parentDevices.push(d);
            });
          });

          // add the parent devices to the devices
          devices.map((d) => {
            const parentId =
              +d?.virtualParentDevice > 0
                ? d.virtualParentDevice
                : d.parentItem;

            const parentDevice = parentDevices.find((pd) => pd.id === parentId);
            if (parentDevice) {
              d.parent = parentDevice;
            }
          });
        }

        return {
          data: {
            devices,
          },
        };
      },
    }),

    getDeviceByField: builder.query<
      { devices: IBoxDevice[] },
      {
        client: string;
        incParent: boolean;
        field: string;
        value: string;
      }
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const { value, field } = _arg;

        const devices: IBoxDevice[] = [];
        const requests: Array<
          MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, {}>>
        > = [];
        const parentRequests: Array<
          MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, {}>>
        > = [];

        const boxInstances = await fetchWithBQ(
          `/boxInstances?data=${passParams({ params: { complete: 1, filters: [{ field: "client", value: _arg.client }] } })}`
        );
        boxInstances?.data?.data?.map((boxInstance) => {
          requests.push(
            fetchWithBQ(
              `/boxInstance/${boxInstance.id}/boxDevices?data=${passParams({
                params: { complete: 1, filters: [{ field, value }] },
              })}`
            )
          );
        });

        const responses = await Promise.all(requests);

        responses.map((response) => {
          if (response?.data?.data?.length > 0) {
            response?.data?.data?.map((d) => devices.push(d));
          }
        });

        return {
          data: {
            devices: devices.length > 0 ? devices : null,
          },
        };
      },
    }),

    getClientContracts: builder.query<
      { contracts: Array<IContract> },
      Array<string>
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const contracts: Array<IContract> = [];
        const calls: IMultiCallItem[] = [];

        _arg.map((clientId) => {
          calls.push({
            path: `/rest/dbo/client/${clientId}/contracts`,
            method: "GET",
            data: { complete: "1" },
          });
        });

        if (calls.length === 0) return { data: { buildings: [] } };

        const multicallResponse = await fetchWithBQ({
          url: `${process.env.NEXT_PUBLIC_SERVER_URL}/rest/multicall`,
          method: "POST",
          body: { calls },
        });

        multicallResponse.data?.responses?.map(
          (response: any, index: number) => {
            if (response.status === "success") {
              try {
                const clientId = _arg[index];
                response?.data?.map((contract) => {
                  contracts.push({
                    id: contract.id,
                    name: contract.name,
                    clientId,
                  });
                });
              } catch (e) {}
            }
          }
        );

        return {
          data: {
            contracts,
          },
        };
      },
    }),

    getClientBuildings: builder.query<
      { buildings: Array<IBuildingObject> },
      Array<IClient>
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const buildings: Array<IBuildingObject> = [];
        const calls: IMultiCallItem[] = [];

        _arg.map(({ id }) => {
          calls.push({
            path: `/rest/dbo/client/${id}/buildings`,
            method: "GET",
            data: { complete: "1" },
          });
        });

        if (calls.length === 0) return { data: { buildings: [] } };

        const multicallResponse = await fetchWithBQ({
          url: `${process.env.NEXT_PUBLIC_SERVER_URL}/rest/multicall`,
          method: "POST",
          body: { calls },
        });

        multicallResponse.data?.responses?.map(
          (response: any, index: number) => {
            if (response.status === "success") {
              try {
                const client = _arg[index];
                response?.data?.map((b) => {
                  buildings.push({ ...b, clientId: client.id });
                });
              } catch (e) {}
            }
          }
        );

        return {
          data: {
            buildings,
          },
        };
      },
    }),

    getClientRoles: builder.query<
      { roles: Array<IClientRole> },
      Array<IClient>
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const roles: Array<IClientRole> = [];
        const calls: IMultiCallItem[] = [];

        _arg.map(({ id }) => {
          calls.push({
            path: `/rest/dbo/client/${id}/clientRoles`,
            method: "GET",
            data: { complete: "1" },
          });
        });

        if (calls.length === 0) return { data: { roles: [] } };
        const multicallResponse = await fetchWithBQ({
          url: `${process.env.NEXT_PUBLIC_SERVER_URL}/rest/multicall`,
          method: "POST",
          body: { calls },
        });

        multicallResponse.data?.responses?.map(
          (response: any, index: number) => {
            if (response.status === "success") {
              try {
                const client = _arg[index];
                response?.data?.map((role) => {
                  roles.push({ ...role, client: client.id });
                });
              } catch (e) {}
            }
          }
        );

        return {
          data: {
            roles,
          },
        };
      },
    }),

    getInductionHistory: builder.query<
      { history: any[] },
      {
        client: string;
        buildings: IBuilding[];
        sites: IBuildingGroup[];
        user: string;
      }
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const { client, buildings, sites, user } = _arg;
        const inductionInvitationsIds: any[] = [];
        const inductionIds: any[] = [];
        const history: any[] = [];

        //GET HISTORY
        const fetchHistoryFilters = {
          complete: "1",
          filters: [
            { field: "annualHS", value: "1" },
            { field: "result", value: "passed" },
            { field: "user", value: user },
          ],
        };
        const { data: fetchHistory } = await fetchWithBQ(
          `/client/${client}/inductionHistories?data=${queryParams({ filters: fetchHistoryFilters })}`
        );

        fetchHistory?.data?.map((h) => {
          if (inductionInvitationsIds.includes(h.inductionInvitation)) return;
          inductionInvitationsIds.push(h.inductionInvitation);
        });

        //GET INDUCTION INVITATIONS - BASED ON HISTORY
        const fetchInvitationsFilters = {
          complete: "1",
          filters: [{ field: "user", value: user }],
          // "filters": {
          //   'OR': inductionInvitationsIds.map(id => {
          //     return { "field": "id", "value": id }
          //   })
          // }
        };

        const { data: fetchInvitations } = await fetchWithBQ(
          `/client/${client}/inductionInvitations?data=${queryParams({ filters: fetchInvitationsFilters })}`
        );
        // const { data: fetchInvitations } = await fetchWithBQ(`/client/${client}/inductionInvitations`);

        fetchInvitations?.data?.map((h) => {
          if (inductionIds.includes(h.induction)) return;
          inductionIds.push(h.induction);
        });

        //GET INDUCTIONS - BASED ON INVITATIONS

        const fetchInductionsFilters = {
          complete: "1",
          // "filters": {
          //   'OR': inductionIds.map(id => {
          //     return { "field": "id", "value": id }
          //   })
          // }
        };

        // const { data: fetchInducitons } = await fetchWithBQ(`/client/${client}/inductions`);
        const { data: fetchInducitons } = await fetchWithBQ(
          `/client/${client}/inductions?data=${queryParams({ filters: fetchInductionsFilters })}`
        );

        fetchHistory?.data?.map((h) => {
          const inductionInvitation = fetchInvitations?.data?.find(
            (i) => i.id === h.inductionInvitation
          );
          const induction = fetchInducitons?.data?.find(
            (i) => i.id === inductionInvitation?.induction
          );

          const tempSite = sites.find((s) => s.id === induction?.site);
          const building = buildings.find((b) => b.id === induction?.building);

          const siteBuildings: string[] = [];
          try {
            JSON.parse(tempSite?.buildings).map((b: string) =>
              siteBuildings.push(b)
            );
          } catch (e) {}
          const site = tempSite
            ? { ...tempSite, buildings: siteBuildings }
            : undefined;

          history.push({
            ...h,
            site,
            building,
            inductionInvitation,
            induction,
          });
        });

        return {
          data: {
            history,
          },
        };
      },
    }),

    //

    getDeviceFieldValues: builder.query<
      { devices: IBoxDeviceWithInstance[] },
      {
        client: string;
      }
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const fieldValuesRequests: Array<
          MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError>>
        > = [];

        const { client } = _arg;
        const boxInstancesIds = [];
        const { data: boxInstancesData } = await fetchWithBQ(
          `/client/${client}/boxInstances?data=${passParams({ params: { complete: 1 } })}`
        );
        boxInstancesData?.data?.map((bi) => {
          boxInstancesIds.push(bi.id);
        });

        boxInstancesIds?.map((id) => {
          fieldValuesRequests.push(
            fetchWithBQ(
              `/boxInstance/${id}/deviceFieldValues?data=${passParams({
                params: { complete: 1 },
              })}`
            )
          );
        });

        const fieldValues = [];

        const responses = await Promise.all(fieldValuesRequests);
        responses.map(({ data, error, meta }) => {
          fieldValues.push(...data?.data);
        });

        return {
          data: {
            data: fieldValues,
          },
        };
      },
    }),

    getAssetsIcon: builder.query<
      { icons: IAssetIcon[] },
      {
        client: string;
        building: string;
        assetIds: string[];
      }
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const { client, building, assetIds } = _arg;

        const response: any[] = [];

        const { data: assetsData } = await fetchWithBQ(
          `/client/${client}/building/${building}/assets?data=${passParams({ params: { complete: 1 } })}`
        );

        const { data: categoryAssetsData } = await fetchWithBQ(
          `/client/${client}/categoryAssets?data=${passParams({ params: { complete: 1 } })}`
        );

        const { data: iconsData } = await fetchWithBQ(
          `/deviceClassIcons?data=${passParams({ params: { complete: 1 } })}`
        );

        //how to await for map to finish?
        await Promise.all(
          assetIds.map(async (assetId) => {
            const findAsset = assetsData?.data.find(
              (asset) => asset.id == assetId
            );
            if (findAsset) {
              const categoryId = +findAsset.categoryId;
              if (categoryId > 0) {
                const findCategoryAsset = categoryAssetsData?.data.find(
                  (asset) => asset.id == categoryId
                );
                if (findCategoryAsset) {
                  const iconId = +findCategoryAsset.icon;
                  if (iconId > 0) {
                    const findIcon = iconsData?.data.find(
                      (icon) => icon.id == iconId
                    );

                    if (findIcon) {
                      const { data: iconData } = await fetchWithBQ(
                        `/file/${findIcon.fileHash}`
                      );
                      response.push({
                        icon: iconData?.data,
                        assetId,
                      });
                    }
                  }
                }
              }
            }
          })
        );

        return {
          data: {
            icons: response,
          },
        };
      },
    }),
    //Device classes with icons

    getDeviceClasses: builder.query<
      { deviceClasses: IDeviceClass[] },
      {
        client: string;
        includeIcons?: boolean;
      }
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const { client, includeIcons = false } = _arg;

        const deviceClasses: IDeviceClass[] = [];

        const { data: deviceClassesData } = await fetchWithBQ(
          `/client/${client}/deviceClasses?data=${passParams({ params: { complete: 1 } })}`
        );

        const { data: iconsData } = await fetchWithBQ(
          `/deviceClassIcons?data=${passParams({ params: { complete: 1 } })}`
        );

        if (includeIcons) {
          await Promise.all(
            deviceClassesData?.data.map(async (deviceClass) => {
              const iconId = +deviceClass.icon;
              if (iconId > 0) {
                const findIcon = iconsData?.data.find(
                  (icon) => icon.id == iconId
                );

                if (findIcon) {
                  const { data: iconData } = await fetchWithBQ(
                    `/file/${findIcon.fileHash}`
                  );
                  deviceClasses.push({
                    ...deviceClass,
                    iconObject: iconData?.data,
                  });
                } else {
                  //icon not found
                  deviceClasses.push(deviceClass);
                }
              }
            })
          );
        } else {
          deviceClasses.push(...deviceClassesData?.data);
        }

        return {
          data: {
            deviceClasses,
          },
        };
      },
    }),

    //get cafm tasks with multiple buildings
    getCafmTasks: builder.query<
      { cafmTasks: any },
      {
        client: string;
        buildings: string[];
        fromDate: number;
        toDate: number;
      }
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const cafmTasks: Record<string, any> = {};
        const { client, buildings, fromDate, toDate } = _arg;

        await Promise.all(
          buildings.map(async (building) => {
            const { data } = await fetchWithBQ(
              `/client/${client}/cafm/tasks?data=${passParams({
                params: {
                  building,
                  period: {
                    from: fromDate,
                    to: toDate,
                  },
                },
              })}`
            );
            cafmTasks[building] = data?.data;
          })
        );

        return {
          data: {
            cafmTasks,
          },
        };
      },
    }),

    getUsageByBuildings: builder.query<
      { usage: IUsageBuilding[] },
      {
        client: string;
        buildings: string[];
        dateFrom: number;
        dateTo: number;
        utility: string;
      }
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const usage: IUsageBuilding[] = [];
        const { client, buildings, dateFrom, dateTo, utility } = _arg;

        await Promise.all(
          buildings.map(async (building) => {
            const { data } = (await fetchWithBQ(
              `/client/${client}/module/sensor/energyUseOverTime?data=${passParams(
                {
                  params: {
                    building,
                    utility,
                    dateFrom,
                    dateTo,
                  },
                }
              )}`
            )) as { data: { costByDate?: any; usageByDate?: any } };

            usage.unshift({
              building,
              costByDate: data?.costByDate,
              usageByDate: data?.usageByDate,
            });
          })
        );

        return {
          data: {
            usage,
          },
        };
      },
    }),
  }),
});

export const {
  useGetInductionHistoryQuery,
  useLazyGetInductionHistoryQuery,
  useGetClientContractsQuery,
  useGetClientBuildingsQuery,
  useGetClientRolesQuery,
  useGetDevicesByQuery,
  useLazyGetDevicesByQuery,
  useGetDevicesAnnotationsQuery,
  useGetAlarmsWithHistoryQuery,
  useLazyGetDevicesAnnotationsQuery,
  useGetDeviceByFieldQuery,
  useGetDeviceFieldValuesQuery,
  useGetAssetsIconQuery,
  useGetDeviceClassesQuery,
  useGetCafmTasksQuery,
  useGetUsageByBuildingsQuery,
} = customQueriesAPI;
