import { Ref } from "react";

import { getHexOpacity } from "@/components/utils/common";
import {
  Button,
  GridItem,
  IconButton,
  useColorModeValue,
  useToken,
  type ButtonProps,
  type GridItemProps,
  type IconButtonProps,
} from "@chakra-ui/react";

export const OrangeButton = (props: ButtonProps & { ref?: Ref<any> }) => {
  //absolutely awesome
  const [hexColor] = useToken("colors", ["accentColor"]);
  const _activeBg = getHexOpacity(hexColor, 70);
  const _hoverBg = getHexOpacity(hexColor, 90);
  const _focusBg = getHexOpacity(hexColor, 80);

  return (
    <Button
      _hover={{ bg: _hoverBg }}
      _active={{ bg: _activeBg }}
      _focus={{ bg: _focusBg }}
      bg="accentColor"
      color="#fff"
      {...props}
    />
  );
};

export const OrangeIconButton = (props: IconButtonProps) => {
  //absolutely awesome
  const [hexColor] = useToken("colors", ["accentColor"]);

  const _activeBg = getHexOpacity(hexColor, 70);
  const _hoverBg = getHexOpacity(hexColor, 90);
  const _focusBg = getHexOpacity(hexColor, 80);

  return (
    <IconButton
      _hover={{ bg: _hoverBg }}
      _active={{ bg: _activeBg }}
      _focus={{ bg: _focusBg }}
      bg="accentColor"
      {...props}
    />
  );
};

export const RedButton = (props: ButtonProps) => {
  return (
    <Button
      _hover={{ bg: "red.400" }}
      _active={{ bg: "red.600" }}
      bg="red.500"
      color="#fff"
      {...props}
    />
  );
};

export const BlueButton = (props: ButtonProps) => {
  return (
    <Button
      _hover={{ bg: "blue.400" }}
      _active={{ bg: "blue.600" }}
      bg="blue.500"
      color="#fff"
      {...props}
    />
  );
};

export const AppGridItem = (props: GridItemProps) => {
  const border = useColorModeValue("gray.200", "gray.800");

  return (
    <GridItem
      p={2}
      borderWidth={1}
      borderRadius={5}
      borderColor={border}
      {...props}
    />
  );
};
